@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Outfit:ital,wght@0,400;0,500;0,700;1,600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    font-family: Outfit;
    color: #2B2B2B;
}

input:focus {
    outline: none;
}

/* for notify server ripple */
.notify_button {
    padding: 0;
    height: 60px;
    width: 60px;
    border-radius: 60px;
    animation: ripple 0.8s linear infinite;
    background-color: rgb(64, 98, 255);
    border: none;
}

/* ripple effect */
@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(64, 98, 255, 0.5),
            0 0 0 30px rgba(64, 98, 255, 0.5),
            0 0 0 60px rgba(64, 98, 255, 0.5),
            0 0 0 90px rgba(64, 98, 255, 0.5);
    }

    100% {
        box-shadow: 0 0 0 30px rgba(64, 98, 255, 0.5),
            0 0 0 60px rgba(64, 98, 255, 0.5),
            0 0 0 90px rgba(64, 98, 255, 0.5),
            0 0 0 120px rgba(64, 98, 255, 0);
    }
}

/* for homepage tabs */
/* Hide the horizontal scrollbar and disable its appearance */
#home-tabs-body {
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

/* For WebKit-based browsers (Chrome, Safari, newer Edge) */
/* Hide the scrollbar */
#home-tabs-body::-webkit-scrollbar {
    width: 0;
}

/* scrollbar */

/* Hide the scrollbar for webkit-based browsers like Chrome and Safari */
.no-scrollbar::-webkit-scrollbar {
    width: 0.5em;
    /* Adjust as needed */
}

.no-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Hide the thumb */
}

/* Hide the scrollbar for Firefox */
.no-scrollbar {
    scrollbar-width: none;
    /* Firefox */
    scrollbar-color: transparent transparent;
    /* Firefox */
}

/*  */

.search-box {
    width: 34px;
    height: 18px;
}

.search-box-transform {
    transition: 0.1s ease-in;
}

.search-box-transform-active {
    width: calc(100vw - 36px);
}

/* for receipt */
:root {
    --default-scale-factor: 1.0908;
}

@media screen and (max-width: 768px) {
    :root {
        --scale-factor: 0.258;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    :root {
        --scale-factor: 0.64;
    }
}

@media screen and (min-width: 1201px) {
    :root {
        --scale-factor: 1.5;
    }
}

.some-element {
    width: calc(var(--scale-factor, var(--default-scale-factor)) * 40.00px);
}